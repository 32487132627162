

body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* importing all fonts here   */

@font-face {
  src: url(./fonts/PlusJakartaDisplay-Bold.otf);
  src: url(./fonts/PlusJakartaDisplay-BoldItalic.otf);
  src: url(./fonts/PlusJakartaDisplay-Italic.otf);
  src: url(./fonts/PlusJakartaDisplay-Light.otf);
  src: url(./fonts/PlusJakartaDisplay-LightItalic.otf);
  src: url(./fonts/PlusJakartaDisplay-Medium.otf);
  src: url(./fonts/PlusJakartaDisplay-MediumItalic.otf);
  src: url(./fonts/PlusJakartaDisplay-Regular.otf);
  src: url(./fonts/PlusJakartaText-Bold.otf);
  src: url(./fonts/PlusJakartaText-BoldItalic.otf);
  src: url(./fonts/PlusJakartaText-Italic.otf);
  src: url(./fonts/PlusJakartaText-Light.otf);
  src: url(./fonts/PlusJakartaText-LightItalic.otf);
  src: url(./fonts/PlusJakartaText-Regular.otf);


 

  font-family: "Plus Jakarta Sans";
}
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap');

/* temp loader css */
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid rgb(0, 0, 0);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: rgb(0, 0, 0) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


/* update data animations text */

.loading {
 
  font-size: 36px;
  font-family: serif;
  font-weight: bold;
  letter-spacing: 4.4px;
  text-transform: capitalize;
  position: absolute;
  overflow: hidden;
  display: flex;
  align-items: center;
  /* transform: translate(-50%, -60%); */
}
.loading:before {
  color: #aaa;
  content: attr(data-loading-text);
}
.loading:after {
  top: 0;
  left: 0;
  width: 0;
  opacity: 1;
  color: #444;
  overflow: hidden;
  position: absolute;
  content: attr(data-loading-text);
  animation: loading 5s infinite;
}
@keyframes loading {
  0% {
    width: 0;
 }
  100% {
    width: 100%;
 }
}

.MuiIconButton-root {
  color: var(--text) !important;
}

.rdrDateDisplayWrapper{
  display: none;
}

.rdrMonthAndYearPickers select{
  color: var(--primary) !important;
}

.MuiPaper-root {
  background-color: var(--cardColor) !important;
}

.MuiInputBase-root {
  color: var(--text) !important;
}

/* change mui select option color */

.MuiMenu-list {
  color: var(--text) !important;
}